import { useMemo } from 'react';

import { useAuth } from '@launchpad/components/providers/AuthProvider/AuthProvider';
import { testWalletsToLowerCase } from '@launchpad/configs/constants/test-wallets';

const testWallets = testWalletsToLowerCase();

const useIsSelfTestUser = () => {
  const { isAuth, launchpadUser } = useAuth();

  return useMemo(() => {
    if (!isAuth) {
      return false;
    }

    return testWallets.includes(launchpadUser?.attributes?.address?.toLowerCase() || '');
  }, [isAuth, launchpadUser]);
};

export default useIsSelfTestUser;
