import Link from 'next/link';

import { ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import { format } from 'date-fns';

import ImageProxy from '@next-image-proxy';

import { LAUNCHPAD_PROJECT_STATUS, LaunchpadProjectListItem, LaunchpadProjectStatus } from '@api';

import BaseInfoTooltip from '@shared/common/components/BaseInfoTooltip';
import { LAUNCHPAD_INTERNAL_ROUTES } from '@shared/constants';
import { formatNumberAsCurrency } from '@shared/helpers/formatters';
import { getDecimalPlaces } from '@shared/helpers/getDecimalPlaces';

interface StatListItem {
  id: string;
  label: ReactNode;
  content: ReactNode;
  tooltip?: ReactNode;
}

const useProjectStatusStats = ({
  project,
  status,
  withEarlyAccess,
}: {
  project: LaunchpadProjectListItem;
  status: LaunchpadProjectStatus;
  withEarlyAccess?: boolean;
}) => {
  const hasRaisingGoalDeposit = Boolean(project?.meta?.deposited);
  const hasRaisingGoal = Boolean(project?.meta?.goal);
  const hasAllocationSize =
    Boolean(project?.meta?.minAllocation) && Boolean(project?.meta?.maxAllocation);
  const isComingSoon =
    project?.attributes?.isComingSoon && status === LAUNCHPAD_PROJECT_STATUS.COMING;

  return useMemo(() => {
    const tokenPriceStat: StatListItem = {
      id: 'token-price',
      label: withEarlyAccess ? 'EATS Price' : 'IDO Price',
      content: (
        <>
          1 {project.attributes.ticker}=
          {project?.meta?.goal && !isComingSoon
            ? formatNumberAsCurrency({
                amount: project?.meta?.price,
                minimumFractionDigits: getDecimalPlaces(project?.meta?.price),
              })
            : 'TBA'}
        </>
      ),
      tooltip: 'Token price for this sale.',
    };

    const allocationSizeStat: StatListItem = {
      id: 'allocation-size',
      label: 'Allocation Size',
      tooltip: 'Allocation range per user.',
      content:
        hasAllocationSize && !isComingSoon
          ? `${formatNumberAsCurrency({
              amount: project?.meta?.minAllocation,
            })}-${formatNumberAsCurrency({
              amount: project?.meta?.maxAllocation,
            })}`
          : 'TBA',
    };

    const participantsStat: StatListItem = {
      id: 'participants',
      label: 'Participants',
      tooltip: 'Sum participants across all pools thus far.',
      content: <>{project?.meta?.participantCount || 0}</>,
    };

    const raisingGoalState: StatListItem = {
      id: 'raising-goal',
      label: 'Raising Goal',
      tooltip: 'Sum deposits across all pools compared to the total fundraising goal to date.',
      content:
        hasRaisingGoal && !isComingSoon
          ? `${formatNumberAsCurrency({
              amount: project?.meta?.deposited,
              maximumFractionDigits: 0,
            })}/${formatNumberAsCurrency({
              amount: project?.meta?.goal,
              maximumFractionDigits: 0,
            })}`
          : 'TBA',
    };

    if (status === LAUNCHPAD_PROJECT_STATUS.ACTIVE) {
      return [raisingGoalState, allocationSizeStat, tokenPriceStat, participantsStat];
    }

    if (status === LAUNCHPAD_PROJECT_STATUS.COMING) {
      const dateStat: StatListItem = {
        id: 'date',
        label: 'Start Date',
        content:
          project.attributes.startDate && !isComingSoon
            ? format(new Date(project.attributes.startDate), 'dd.MM.yy, HH:mm')
            : 'COMING SOON',
        tooltip: 'Starting Date of the IDO.',
      };

      const saleGoalStat: StatListItem = {
        id: 'sale-goal',
        label: 'Raising goal',
        tooltip: 'Sum deposits across all pools compared to the total fundraising goal to date.',
        content:
          hasRaisingGoal && !isComingSoon
            ? formatNumberAsCurrency({
                amount: project?.meta?.goal,
                maximumFractionDigits: 0,
              })
            : 'TBA',
      };

      return [saleGoalStat, allocationSizeStat, tokenPriceStat, dateStat];
    }

    const raisedStat: StatListItem = {
      id: 'raised',
      label: 'Raised',
      tooltip: 'Total funds raised across all pools.',
      content: (
        <>
          {hasRaisingGoalDeposit
            ? formatNumberAsCurrency({
                amount: project?.meta?.deposited,
                maximumFractionDigits: 0,
              })
            : 'TBA'}
        </>
      ),
    };

    // LAUNCHPAD_PROJECT_STATUS.PASSED
    return [raisedStat, tokenPriceStat, participantsStat];
  }, [status, project]);
};

const STATUS_TAG_CONFIG: Record<
  LaunchpadProjectStatus,
  { label: string; className: string } | null
> = {
  [LAUNCHPAD_PROJECT_STATUS.ACTIVE]: {
    label: 'SALE IS LIVE',
    className: 'bg-green-50 text-green-700',
  },
  [LAUNCHPAD_PROJECT_STATUS.PASSED]: {
    label: 'SALE FINISHED',
    className: 'bg-neutral-100 text-neutral-600',
  },
  [LAUNCHPAD_PROJECT_STATUS.COMING]: null,
} as const;

const CardProject = ({
  project,
  status,
  withEarlyAccess,
  className,
}: {
  status: LaunchpadProjectStatus;
  project: LaunchpadProjectListItem;
  withEarlyAccess?: boolean;
  className: string;
}) => {
  const stats = useProjectStatusStats({ project, status, withEarlyAccess });
  const statusTag = STATUS_TAG_CONFIG[status];

  return (
    <Link
      href={LAUNCHPAD_INTERNAL_ROUTES.project(project.attributes.slug)}
      className={classNames(
        'w-full rounded-lg border border-primary-200 flex flex-col pb-6 overflow-hidden',
        className,
      )}
    >
      <ImageProxy
        alt={project?.attributes?.name}
        className="w-full object-cover aspect-[16/9] flex justify-center items-center overflow-hidden"
        width={1920}
        height={1080}
        src={project?.attributes?.bannerSmallUrl}
      />

      <div className="px-5 z-2 relative">
        <div className="w-14 h-14 rounded-full overflow-hidden mt-[-28px] mb-2 z-2 border-4 border-neutral-50 bg-neutral-50">
          <ImageProxy
            alt={`${project?.attributes?.name} logo`}
            width={56}
            height={56}
            quality={80}
            className="aspect-square border border-neutral-100 rounded-full"
            src={project?.attributes?.logoUrl}
          />
        </div>

        <div className="flex justify-start mb-2 items-baseline">
          <h4 className="t-h3 text-neutral-600 truncate">{project?.attributes?.name}</h4>
          <span className="t text-sm text-neutral-500 pl-2">{project?.attributes?.ticker}</span>
        </div>
        <p className="h-[60px] text-neutral-500 mb-6 text-sm line-clamp-3">
          {project?.attributes.description}
        </p>
        <ul className="gap-4 flex flex-col">
          {stats.map(({ id, label, content, tooltip }) => {
            return (
              <li key={id} className="flex justify-between w-full">
                <span className="text-sm uppercase font-medium text-neutral-500">{label}</span>
                <span className="font-semibold text-neutral-600">
                  {content}
                  {tooltip && (
                    <>
                      {' '}
                      <BaseInfoTooltip content={tooltip} />
                    </>
                  )}
                </span>
              </li>
            );
          })}
        </ul>

        {statusTag && (
          <div
            className={classNames(
              'flex justify-center items-center rounded-lg py-1 mt-2 min-h-8 uppercase leading-6 font-semibold',
              statusTag.className,
            )}
          >
            {statusTag.label}
          </div>
        )}
      </div>
    </Link>
  );
};

export default CardProject;
