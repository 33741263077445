import ImageProxy from '@next-image-proxy';

import ExternalLink from '@uikit/components/ExternalLink/ExternalLink';

import { launchpadSocials } from '@launchpad/components/layout/Footer';

const Socials = () => {
  return (
    <section className="gradient-black-to-white-ttb p-8 flex justify-center items-center flex-col gap-8 rounded-lg">
      <ImageProxy
        src={'launchpad/launchpad-logo-in-waves.png'}
        alt={'socials-launchpad-logo-in-waves'}
        className="w-[120px] aspect-square"
        width={500}
        height={500}
      />

      <div className="text-center max-w-[610px]">
        <h4 className="t-h3 text-[32px] text-neutral-600 pb-6">
          Join Our <span className="text-secondary-700">4.5M+</span> Strong Community
        </h4>
        <p className="text-neutral-700 font-medium leading-7">
          Discover, share, and discuss innovative projects with fellow enthusiasts. Stay updated
          with the latest news!
        </p>
        <div className="pt-8">
          <ul className="flex justify-center items-center gap-2 md:gap-6 max-lg:flex-wrap">
            {launchpadSocials.map(({ id, link, CircleIcon }) => (
              <li key={id}>
                <ExternalLink
                  href={link}
                  className="flex justify-center items-center w-12 h-12 md:w-16 md:h-16 rounded-md bg-neutral-50 border border-neutral-100"
                >
                  <CircleIcon className="icon-lg w-9 h-9 md:w-12 md:h-12" />
                </ExternalLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Socials;
