import { useState } from 'react';

import { useInterval } from 'usehooks-ts';

// NOTE: Simple implementation of cooling down timer to block UI triggers and show time to wait
// e.g. Resending email after 1 min timeout to prevent API spamming
const useCoolDownTimeout = (
  initialTimeout: number,
  {
    delay = 1000,
    callback,
  }: {
    delay?: number;
    callback?: () => void;
  } = {},
): [number, () => void] => {
  const [coolDownTimeout, setCoolDownTimeout] = useState(0);

  useInterval(
    () => {
      setCoolDownTimeout((old) => old - 1);
    },
    coolDownTimeout ? delay : null,
  );

  const resetTimeout = () => {
    setCoolDownTimeout(initialTimeout);

    if (callback) {
      callback(); // Execute the optional callback if provided
    }
  };

  return [coolDownTimeout, resetTimeout];
};

export default useCoolDownTimeout;
