// Wallets dev/qa teams and stakeholders

export const TEST_WALLETS = [
  // @Alexandr Gaidai
  '0x423a9540Dd1736A448B19E50E96C37E01A59919D',

  // @Denis Harbar
  '0x4Ee463d6e90764AC34880024305C2810866432D',

  // @Ruslan Makaev
  '0x876f6629fce3C6DebC00FA18a237689121B62955',

  // @Fedor Sedov
  '0x2C5459BB28254cc96944c50090f4Bd0eF045A937',

  // @Aleksandr Aganov
  '0xae4922498B14F8C954fA5F92808810e49a96f7983',

  // @Michael Landsberger
  '0x601d71206eE77ace36Bff648a66837437142B012',

  // @Andrey Nayman
  '0x6d8212739C6902ab56B34E3AEB84e4Ad30c89F33',

  // @Benjamin Vodovozov
  '0x75632C9236b93FC5D74189497Af2D257d7599A15',

  // @Roman Mikhailov
  '0x09DA26F74D76f953688a08245423d610B86C5aCd',
  '0x9E8fc567Cb67A53674761296c9deD6661dBd12de',

  // @Anastasia Isakov
  '0xdd441f55e8958f5F80A12Cca920F9A064E30faFF',

  // @Aleksandar Ikodinovic
  '0x8f6E8017b8B4EACbf6f76f7b877774f5ddfC2AD2',

  // Viktoria Nalobina
  '0x81ad010DcA1f180fc09Fc916016556E02301Cc4a',

  // Gitai Zakh,
  '0x8Ec5256F23ee9E4f80331A0990B438dd641Ff7E5',

  // Matan Kelly
  '0x1933f199e85e309c5300c99feab6a41c9125b05b',

  // Pentesters
  '0xac56C67253A6C471D9e5aC3EB5F2427fBcA3DfEC',
  '0xfb0fBda5F5627c6CDbD9637bF4D854420E7497cb1',
  '0xEcEB731Ab1a317c5baF01b64A2D0c7CdCbCd10E',
  '0x03Fee1A1dA999BF63860c17a24EdcB830d7BccC',
  '0x94d4211ceC765EF87311cd428af8d6d9F7c04f40',
  '0x4d43471757743f83D32AEDa600a7cD68F47a220e84',
  '0x720B2CD147DDCdA32D3Df18aF771B0CE22F545F0',
];

export const testWalletsToLowerCase = () => {
  return TEST_WALLETS.map((address) => address.toLocaleLowerCase());
};
