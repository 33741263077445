import React, { ComponentPropsWithoutRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import classNames from 'classnames';

import { useSubscribeToLaunchpadNewsletterMutation } from '@query';

import displayErrorToast from '@shared/helpers/displayErrorToast';
import { isEmail } from '@shared/helpers/validation';
import useCoolDownTimeout from '@shared/hooks/useCoolDownTimeout';

const ATTEMPTS_COUNT = 3;

const SubscribeForm: React.FC = () => {
  const setAttempts = useState(ATTEMPTS_COUNT)[1];

  const [timeout, resetTimeout] = useCoolDownTimeout(30, {
    callback() {
      setAttempts(ATTEMPTS_COUNT);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<{ email: string }>();

  const { mutateAsync } = useSubscribeToLaunchpadNewsletterMutation();

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      if (timeout) {
        return;
      }

      if (!email) {
        return toast.info('Please enter Email address');
      }

      const isEmailValid = isEmail(email);

      if (!isEmailValid) {
        return toast.error('Please enter a valid Email address');
      }

      await mutateAsync(email);

      toast.success('Subscription successful!');
      reset();
    } catch (error) {
      displayErrorToast({ error });
    } finally {
      setAttempts((prev) => {
        const newAttempts = prev - 1;

        if (newAttempts === 0) {
          resetTimeout();
        }

        return newAttempts;
      });
    }
  });

  return (
    <form
      onSubmit={onSubmit}
      className="flex gap-2 justify-between items-center flex-grow w-[380px] max-w-full border border-neutral-300 pl-4 pr-2 rounded-full h-fit"
    >
      <input
        disabled={Boolean(timeout)}
        type="email"
        placeholder="Email Address*"
        className="font-light text-xs flex-grow outline-none border-none h-full bg-transparent py-3 min-w-[120px]"
        {...register('email')}
      />

      <button
        type="submit"
        className="button button-outline-secondary rounded-full my-2 whitespace-nowrap"
        disabled={Boolean(isSubmitting || timeout)}
      >
        {isSubmitting ? 'Subscribing...' : 'Subscribe'} {timeout || ''}
      </button>
    </form>
  );
};

const NewsletterSubscribeWidget = ({
  className,
  ...props
}: ComponentPropsWithoutRef<'section'>) => {
  return (
    <section
      className={classNames(
        'flex max-lg:flex-col items-center gap-2 md:gap-4 p-2 sm:p-12 relative border border-secondary-600 rounded',
        className,
      )}
      {...props}
    >
      <div>
        <p className="text-[20px] md:mr-[260px] mb-2.5">Never want to miss a sale?</p>

        <h3 className="text-[24px] md:text-[32px] mb-2 md:mb-6 font-semibold">
          Sign up for our newsletter and get the latest news and updates.
        </h3>
      </div>

      <SubscribeForm />
    </section>
  );
};

export default NewsletterSubscribeWidget;
